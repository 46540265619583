import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';

import { CoreserviceService } from '../coreservice.service';
import { WrongMeasureDialogComponent } from './wrong_measure_dialog.component';
import { IsoverProvider } from '../data.service';

@Component({
  selector: 'app-fill',
  templateUrl: './fill.component.html',
  styleUrls: ['./fill.component.scss']
})

export class FillComponent implements OnInit {
  private sub: any;

  idPiece: number;
  products: any;
  slide1 = 0;
  slide2 = 0;
  product: any;
  listFields: any[] = [];
  longueurStep = 0;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private isoprovider: IsoverProvider,
    private coreService: CoreserviceService
  ) { }

  formatLabel(value: number | null) {
    if (!value) { return 0; }

    if (value >= 1000) {
      return Math.round(value / 1000) + 'cm';
    }

    return value;
  }

  ngOnInit() {
    this.coreService.showBackButton.emit({ isVisible: true, url: `/detail/2/2` });

    this.sub = this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.isoprovider.getPieces().subscribe( res => {
        this.products = res;
        this.product = res[this.idPiece];
        this.coreService.setIdProduct.emit(this.idPiece);
        this.coreService.setTitle.emit(this.product.productName);
        this.product.steps.forEach(element => {
          this.longueurStep = element.time + this.longueurStep;
        });

        this.somethingChanged();
      });
    });
  }

  somethingChanged() {
    if (this.idPiece === 0) {
      // If produit 1
      if ((this.product.fields[0].val + this.product.fields[1].val) * 2 > 280) {
        const dialogRef = this.dialog.open(WrongMeasureDialogComponent, {
          data: {
            title: this.product.productName,
            content: ``,
            closeText: 'OK'
          }
        });

        dialogRef.afterClosed().subscribe( result => {
          this.product.fields[0].val = this.product.fields[0].initialValue;
          this.product.fields[1].val = this.product.fields[1].initialValue;
        });

      } else {
        this.isoprovider.setDimensions(this.product.fields);
      }

    } else if (this.idPiece === 1 || this.idPiece ===  2) {

      // Si S shaped
      if (this.idPiece === 2) {
        this.product.fields[3].val = Math.ceil(this.product.fields[2].val * Math.sqrt(2));
      }

      if ((this.product.fields[0].val + this.product.fields[1].val + this.product.fields[2].val) > 109) {
        const dialogRef = this.dialog.open(WrongMeasureDialogComponent, {
          data: {
            title: this.product.productName,
          }
        });

        dialogRef.afterClosed().subscribe( result => {
          this.product.fields[0].val = this.product.fields[0].initialValue;
          this.product.fields[1].val = this.product.fields[1].initialValue;
          this.product.fields[2].val = this.product.fields[2].initialValue;
        });


      } else {
        this.isoprovider.setDimensions(this.product.fields);
      }

    } else if (this.idPiece === 3) {
      this.product.fields[3].val = (this.product.fields[0].val + this.product.fields[1].val - this.product.fields[2].val) / 2;

    } else if (this.idPiece === 4) {
      this.product.fields[6].val =
        Math.ceil(
          Math.sqrt(
            Math.pow(
              (this.product.fields[4].val - this.product.fields[2].val),
              2
            ) + Math.pow(
              (this.product.fields[5].val - this.product.fields[0].val - this.product.fields[1].val),
              2
            )
          )
        );

      this.product.fields[7].val =
        this.product.fields[6].val - (
          this.product.fields[5].val -
          this.product.fields[0].val -
          this.product.fields[1].val
        );
    }
  }
}
