import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common';

import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})

export class AppHeaderComponent {
  @Input() title: string;

  backVisible = false;
  backURL: string;
  idPiece: number;
  idProduct: string;
  idStep = 0;
  letitre: string;
  shouldShowBackButton: boolean;

  @Output() openNav = new EventEmitter();
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(
    private coreService: CoreserviceService,
    public _location: Location
  ) {

    this.coreService.showBackButton.subscribe((value) => {
      this.shouldShowBackButton = value.isVisible;
      this.backURL = value.url;
    });

    this.coreService.setIdProduct.subscribe((value) => {
      this.idProduct = value;
    });

    this.coreService.setIdPiece.subscribe((idPiece) => {
      this.idPiece = idPiece;
    });

    this.coreService.setIdStep.subscribe((value) => {
      this.idStep = value;
    });

    this.coreService.setTitle.subscribe((value) => {
      this.letitre = value;
    });
  }

  openSideNav(e: any) {
    this.coreService.toggleSidebar.emit(true);
  }
}
