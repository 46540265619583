import { Component, OnInit, ViewChild } from '@angular/core';
import { IsoverProvider } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { Location } from '@angular/common';

import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-modaletip',
  templateUrl: './modaletip.component.html',
  styleUrls: ['./modaletip.component.scss']
})

export class ModaletipComponent implements OnInit {
  config: any;
  idBstep = 0;
  idPiece = 0;
  idStep: number;
  index: number;
  product: any;
  products: any;
  swipeIndex: number;
  transform: number;

  @ViewChild('SwiperDirective') directiveRef: SwiperDirective;

  constructor(
    private _router: Router,
    private coreService: CoreserviceService,
    private isoprovider: IsoverProvider,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  public onIndexChange(index: number): void {
    this.swipeIndex = index + 1;
  }

  ngOnInit() {
    this.index = 0;
    this.swipeIndex = 1;
    this.transform = 100;
    this.config = {
      pagination: false,
      direction: 'horizontal',
      paginationClickable: true,
      navigation: {
        nextEl: '.next',
        prevEl: '.prev',
      },
      spaceBetween: 30
    };

    this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.idBstep = +params['idBstep'];
      this.idStep = +params['idStep'];

      this.coreService.showBackButton.emit({
        isVisible: true,
        url: `/detail/${this.idPiece}/${this.idStep}`
      });

      this.isoprovider.getPieces().subscribe( pieces => {
        this.products = pieces;
        this.product = pieces[this.idPiece];
      });
    });
  }
}
