import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

import { IsoverProvider } from '../data.service';
import { CoreserviceService } from '../coreservice.service';
import { nicoListTransitionY } from '../../animations/pages/nicoanims';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [ nicoListTransitionY ],
  host: {
    '[@nicoListTransitionY]': ''
  }
})
export class HomeComponent implements OnInit, OnDestroy  {

  constructor(
    public isoprovider: IsoverProvider,
    private titleService: Title,
    private coreservice: CoreserviceService,
    private meta: Meta,
  ) { }

  products: any;

  ngOnInit() {
    this.coreservice.showBackButton.emit({ isVisible: false });

    const val1 = 0;

    this.meta.addTag({
      name: 'description',
      content: `L'application Climaver vous permet de réaliser des conduits
      Climaver en toute simplicité, étape par étape. Téléchargez-là
      gratuitement !`
    });
    this.meta.addTag({ name: 'author', content: 'Southside Interactive' });
    this.coreservice.toggleProgress.emit({ val1 });
    this.coreservice.setTitle.emit( 'Isover' );
    this.titleService.setTitle('Application Climaver - Isover');
    this.isoprovider.getPieces().subscribe( res => { this.products = res; });
  }

  ngOnDestroy() {
    this.products = '';
  }

}
