import {Component, ViewChild, OnInit} from '@angular/core';
import {CoreserviceService} from './coreservice.service';
import * as NoSleep from './nosleep.js';
import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  sequence,
  animateChild
} from '@angular/animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('myAnimation', [
      //  transition('* <=> *', [
      //    query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
      //    query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
      //    sequence([
      //      query(':leave', animateChild(), {optional: true}),
      //      group([
      //        query(':leave', [
      //          style({ transform: 'translateY(0%)' }),
      //          animate('300ms ease', style({ transform: 'translateY(-100%)' }))
      //        ], {optional: true}),
      //        query(':enter', [
      //          style({ transform: 'translateY(100%)' }),
      //          animate('300ms ease',
      //            style({ transform: 'translateY(0%)' })),
      //        ], {optional: true}),
      //      ]),
      //      query(':enter', animateChild(), {optional: true}),
      //    ])
      //  ]),
      //  transition('PiecePage <=> ListPage', [
      //   query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
      //   query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
      //   sequence([
      //     query(':leave', animateChild(), {optional: true}),
      //     group([
      //       query(':leave', [
      //         style({ transform: 'translateY(0%)' }),
      //         animate('300ms ease', style({ transform: 'translateY(-100%)' }))
      //       ], {optional: true}),
      //       query(':enter', [
      //         style({ transform: 'translateY(100%)' }),
      //         animate('300ms ease',
      //           style({ transform: 'translateY(0%)' })),
      //       ], {optional: true}),
      //     ]),
      //     query(':enter', animateChild(), {optional: true}),
      //   ])
      // ]),
      transition('HomePage <=> PiecePage', [
        /* order */
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('PiecePage <=> ListPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('PiecePage <=> FillPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('PiecePage <=> DetailPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('* <=> contactPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('* <=> lexiconPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('ListPage <=> ToolDetailPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
        query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
        sequence([
          query(':leave', animateChild(), {optional: true}),
          group([
            query(':leave', [
              style({ transform: 'translateY(0%)' }),
              animate('300ms ease', style({ transform: 'translateY(-100%)' }))
            ], {optional: true}),
            query(':enter', [
              style({ transform: 'translateY(100%)' }),
              animate('300ms ease',
                style({ transform: 'translateY(0%)' })),
            ], {optional: true}),
          ]),
          query(':enter', animateChild(), {optional: true}),
        ])
      ]),
      transition('ListPage <=> BigStepPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('FillPage <=> DetailPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('BigStepPage <=> DetailPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
        query(':enter', style({ transform: 'translateX(100%)' })),
        sequence([
          query(':leave', animateChild()),
          group([
            query(':leave', [
              style({ transform: 'translateX(0%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(-100%)' }))
            ]),
            query(':enter', [
              style({ transform: 'translateX(100%)' }),
              animate('500ms cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateX(0%)' })),
            ]),
          ]),
          query(':enter', animateChild()),
        ])
      ]),
      transition('DetailPage <=> ModaleTechPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
        query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
        sequence([
          query(':leave', animateChild(), {optional: true}),
          group([
            query(':leave', [
              style({ transform: 'translateY(0%)' }),
              animate('300ms ease', style({ transform: 'translateY(-100%)' }))
            ], {optional: true}),
            query(':enter', [
              style({ transform: 'translateY(100%)' }),
              animate('300ms ease',
                style({ transform: 'translateY(0%)' })),
            ], {optional: true}),
          ]),
          query(':enter', animateChild(), {optional: true}),
        ])
      ]),
      transition('DetailPage <=> ModaleTipPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
        query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
        sequence([
          query(':leave', animateChild(), {optional: true}),
          group([
            query(':leave', [
              style({ transform: 'translateY(0%)' }),
              animate('300ms ease', style({ transform: 'translateY(-100%)' }))
            ], {optional: true}),
            query(':enter', [
              style({ transform: 'translateY(100%)' }),
              animate('300ms ease',
                style({ transform: 'translateY(0%)' })),
            ], {optional: true}),
          ]),
          query(':enter', animateChild(), {optional: true}),
        ])
      ]),
      transition('DetailPage <=> videoPage', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), {optional: true}),
        query(':enter', style({ transform: 'translateY(100%)' }), {optional: true}),
        sequence([
          query(':leave', animateChild(), {optional: true}),
          group([
            query(':leave', [
              style({ transform: 'translateY(0%)' }),
              animate('300ms ease', style({ transform: 'translateY(-100%)' }))
            ], {optional: true}),
            query(':enter', [
              style({ transform: 'translateY(100%)' }),
              animate('300ms ease',
                style({ transform: 'translateY(0%)' })),
            ], {optional: true}),
          ]),
          query(':enter', animateChild(), {optional: true}),
        ])
      ])
    ])
  ]
})

export class AppComponent implements OnInit {
  @ViewChild('sidenav') sideNav: any;
  wakeLockEnabled = true;
  noSleep = new NoSleep();
  routes = [
    { path: '/', name: 'Home' },
  ];

  constructor(private coreservice: CoreserviceService) {
    this.noSleep.enable();
  }

  prepareRoute(outlet) {
    return outlet.activatedRouteData.animation;
  }

  enableNoSleep() {
    this.noSleep.enable();
    this.wakeLockEnabled = true;
  }

  disableNoSleep() {
    this.noSleep.disable();
    this.wakeLockEnabled = false;
  }

  ngOnInit() {
    this.coreservice.toggleSidebar.subscribe(() => {
      // open your sidebar by setting classes, whatever

      this.sideNav.toggle();
    });
  }
}
