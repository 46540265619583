import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCardModule, MatIconModule, MatListModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { GtagModule } from 'angular-gtag';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppRoutingModule } from './app-routing.module';
import { BigstepdetailComponent } from './bigstepdetail/bigstepdetail.component';
import { ContactComponent } from './contact/contact.component';
import { DialogComponent } from './dialog.component';
import { DownloadComponent } from './download/download.component';
import { FillComponent } from './fill/fill.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { IsoverProvider } from '../app/data.service';
import { LexiconComponent } from './lexicon/lexicon.component';
import { ListtoolsComponent } from './listtools/listtools.component';
import { ModaletechComponent } from './modaletech/modaletech.component';
import { ModaletipComponent } from './modaletip/modaletip.component';
import { PieceComponent } from './piece/piece.component';
import { ToolComponent } from './tools/tool.component';
import { VideotutoComponent } from './videotuto/videotuto.component';
import { WrongMeasureDialogComponent } from './fill/wrong_measure_dialog.component';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    BigstepdetailComponent,
    ContactComponent,
    DownloadComponent,
    DialogComponent,
    FillComponent,
    FooterComponent,
    HomeComponent,
    LexiconComponent,
    ListtoolsComponent,
    ModaletechComponent,
    ModaletipComponent,
    PieceComponent,
    ToolComponent,
    VideotutoComponent,
    WrongMeasureDialogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DeviceDetectorModule.forRoot(),
    FormsModule,
    GtagModule.forRoot({ trackingId: 'UA-91101027-4', trackPageviews: true }),
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    SwiperModule,
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : [],
  ],
  entryComponents: [
    DialogComponent,
    WrongMeasureDialogComponent,
  ],
  providers: [IsoverProvider, Title],
  bootstrap: [AppComponent]
})

export class AppModule { }
