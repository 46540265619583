import { trigger, stagger, animate, style, group, query as q, transition, keyframes } from '@angular/animations';
export function query(s, a, o = { optional: true }) { return q( s, a, o); }

export const nicoListTransitionY = trigger('nicoListTransitionY', [
  transition(':enter', [
    query('.elemN', style({ opacity: 0 })),
    query('.elemN', stagger(100, [
      style({ transform: 'translateY(100px)' }),
      animate('0.4s cubic-bezier(.75,-0.48,.26,1.52)', style({transform: 'translateY(0px)', opacity: 1})),
    ]), { optional: true }),
  ])
]);
