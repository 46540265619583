import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IsoverProvider } from '../data.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CoreserviceService } from '../coreservice.service';
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-bigstepdetail',
  templateUrl: './bigstepdetail.component.html',
  styleUrls: ['./bigstepdetail.component.scss']
})

export class BigstepdetailComponent implements OnInit, OnDestroy {
  @ViewChild('bigSlider') componentRef?: SwiperComponent;
  @ViewChild('SwiperDirective') directiveRef: SwiperDirective;

  config: any;
  dimensions: any[] = [];
  idPiece: number;
  index: any;
  indexSwipe = 0;
  isRatingMode = false;
  product: any;
  products: any;
  selectedIndex: number;
  smallStepCurrent = 1;
  stepDimensions: any[] = [];
  stepId: number;
  totalSubSteps = 0;
  transform: number;

  constructor(
    private isoprovider: IsoverProvider,
    private route: ActivatedRoute,
    private titleService: Title,
    public coreservice: CoreserviceService,
  ) { }

  public checkIndex() {
    if (this.indexSwipe < this.product.steps.length - 1) {
      this.indexSwipe++;
      this.coreservice.setIdStep.emit(this.indexSwipe);
    }
  }

  public checkIndexBack() {
    if (this.indexSwipe > 0) {
      this.indexSwipe =  this.indexSwipe - 1;
      this.coreservice.setIdStep.emit(this.indexSwipe);
    }
  }

  openSideNav(e: any) {
    this.coreservice.toggleSidebar.emit();
  }

  public onIndexChange(index: number): void {
    this.coreservice.setIdStep.emit(this.indexSwipe);
  }

  onChange(enable: boolean) {
    if (enable) {
      this.isRatingMode = true;
    } else {
      this.isRatingMode = false;
    }
  }

  public openModaleTech() {
    alert('openmodaletech');
  }

  calculateProgress() {

  }

  ngOnInit() {
    this.coreservice.checkIndex.subscribe((value) => {
      this.checkIndex();
    });

    this.coreservice.checkIndexBack.subscribe((value) => {
      this.checkIndexBack();
    });

    this.coreservice.setIdStep.subscribe((value) => {
      if (value === undefined) { return; }
      this.stepId = value;
      this.stepDimensions = this.dimensions.filter((dimension, index, array) => {
        return this.product.steps[this.stepId].relatedMeasures.includes(index + 1);
      });
    });

    this.indexSwipe = 0;
    this.stepId = 0;
    this.coreservice.setIdStep.emit(this.stepId);

    this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.coreservice.showBackButton.emit({ isVisible: true, url: `/piece/${this.idPiece}` });
      this.coreservice.setIdPiece.emit(this.idPiece);
      const currentIndex = +params['currentIndex'];

      if (currentIndex !== undefined) {
        this.indexSwipe = currentIndex;
        this.coreservice.setIdStep.emit(currentIndex);
      }

      this.isoprovider.getPieces().subscribe( pieces => {
        this.products = pieces;
        this.product = pieces[this.idPiece];
        this.titleService.setTitle(this.product.productName);
        this.coreservice.setIdProduct.emit(this.idPiece);
        this.coreservice.setTitle.emit(this.product.productName);
        this.isoprovider.getDimensions().subscribe( dimensions => {
          if (dimensions) {
            dimensions = dimensions;
          } else {
            dimensions = this.product.fields;
          }

          this.dimensions = dimensions;
          this.stepDimensions = this.dimensions.filter((value, index, array) => {
            return this.product.steps[this.stepId].relatedMeasures.includes(index + 1);
          });
        });
      });
    });
  }

  ngOnDestroy() {
    this.coreservice.setIdProduct.emit(undefined);
    this.coreservice.setIdPiece.emit(undefined);
    this.coreservice.setIdStep.emit(undefined);
  }
}
