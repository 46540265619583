import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Piece {
  productName: string;
  steps: any[];
  fields: any[];
  tools: any[];
}

export interface Tool {
  idTool: number;
  nameTool: string;
  iconeTool: string;
  details: string[];
}

/*
  Generated class for the IsoverProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
 */
@Injectable()
export class IsoverProvider {
  toolData = {
    'tools' : [
      { 'idTool': 1, 'nameTool': 'Red MM tool', 'iconeTool': 'assets/products/layout/icn_redmmtool.png', 'details': ['assets/products/layout/icn_redmmtool_detail.png'] },
      { 'idTool': 2, 'nameTool': 'Blue MM tool', 'iconeTool': 'assets/products/layout/icn_bluemmtool.png', 'details': ['assets/products/layout/icn_bluemmtool_detail.png'] },
      { 'idTool': 3, 'nameTool': 'Black MM tool', 'iconeTool': 'assets/products/layout/icn_blackmmtool.png', 'details': ['assets/products/layout/icn_blackmmtool_detail.png'] },
      { 'idTool': 4, 'nameTool': 'White MM tool', 'iconeTool': 'assets/products/layout/icn_whitemtrtool.png', 'details': ['assets/products/layout/icn_whitemtrtool_detail.png'] },
      { 'idTool': 5, 'nameTool': 'Yellow MM tool', 'iconeTool': 'assets/products/layout/icn_yellowmtrtool.png', 'details': ['assets/products/layout/icn_yellowmtrtool_detail.png'] },
      { 'idTool': 6, 'nameTool': 'Knife tool', 'iconeTool': 'assets/products/layout/icn_knife.png', 'details': ['assets/products/layout/icn_knife_detail_a.png', 'assets/products/layout/icn_knife_detail_b.png'] },
      { 'idTool': 7, 'nameTool': 'Stapler', 'iconeTool': 'assets/products/layout/icn_stapler.png', 'details': [] },
      { 'idTool': 8, 'nameTool': 'Climaver Tape', 'iconeTool': 'assets/products/layout/icn_climavertape.png', 'details': [] },
      { 'idTool': 9, 'nameTool': 'Climaver Glue', 'iconeTool': 'assets/products/layout/icn_climaverglue.png', 'details': [] },
      { 'idTool': 10, 'nameTool': 'Pen', 'iconeTool': 'assets/products/layout/icn_pen.png', 'details': [] },
      { 'idTool': 11, 'nameTool': 'Climaver Ruler', 'iconeTool': 'assets/products/layout/icn_climaverruler.png', 'details': [] },
      { 'idTool': 12, 'nameTool': 'Scraper', 'iconeTool': 'assets/products/layout/icn_scraper.png', 'details': [] }
    ]
  };

  piecesData = {
    'pieces': [
      {
        'productName': 'Straight',
        'productNameFrench': 'Tronçon Droit',
        'productNameDeutsch': 'Gerader Kanal',
        'family': [],

        'fields': [
          { 'color': '#FF8800', 'min': 30, 'max': 20, 'initialValue': 30, 'val': 30, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#00FF33', 'min': 40, 'max': 20, 'initialValue': 40, 'val': 40, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' }
        ],

        'steps': [
          { 'idx': 1, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 2, 'modalTech': [1, 2, 3, 4, 5], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [1] },
          { 'idx': 3, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [2] },
          { 'idx': 4, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [1] },
          { 'idx': 5, 'modalTech': [1, 2, 3, 4, 5, 6], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [2] },
          { 'idx': 6, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 7, 'modalTech': [1, 2, 3], 'modalTip': [1], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 8, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 9, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 10, 'modalTech': [1, 2, 3], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 11, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 12, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] },
          { 'idx': 13, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/H9KOzWHVj64', 'relatedMeasures': [] }
        ],

        'tools': [
          1, 2, 7, 8, 11, 12
        ]
      },

      {
        'productName': 'Elbow',
        'productNameFrench': 'Coude de 90°',
        'productNameDeutsch': 'Bögen von 90°',
        'family': [0],

        'fields': [
          { 'color': '#FF8800', 'min': 20, 'max': 30, 'initialValue': 25, 'val': 25, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#00FF33', 'min': 50, 'max': 20, 'initialValue': 50, 'val': 50, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#FF00CC', 'min': 29, 'max': 20, 'initialValue': 29, 'val': 29, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' }
        ],

        'steps': [
          { 'idx': 1, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 2, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [1, 2, 3] },
          { 'idx': 3, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [1, 2, 3] },
          { 'idx': 4, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [1, 2, 3] },
          { 'idx': 5, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [1, 2, 3] },
          { 'idx': 6, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 7, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 8, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 9, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 10, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 11, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 12, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 13, 'modalTech': [1], 'modalTip': [1, 2], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 14, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 15, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 16, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] },
          { 'idx': 17, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/5jW7nEJ28t0', 'relatedMeasures': [] }
        ],
        'tools': [
          4, 5, 8, 9, 10, 12
        ]
      },

      {
        'productName': 'Offset',
        'productNameFrench': 'Ramification simple en R',
        'productNameDeutsch': 'R-Abzweig',
        'family': [0],

        'fields': [
          {'color': '#FF8800', 'min': 30, 'max': 20, 'initialValue': 30, 'val': 30, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual'},
          {'color': '#00FF33', 'min': 50, 'max': 20, 'initialValue': 50, 'val': 50, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual'},
          {'color': '#FF00CC', 'min': 29, 'max': 20, 'initialValue': 29, 'val': 29, 'texte': 'Dimension (cm)', 'indication': 'This fits the obstacle you want to avoid', 'typeF': 'manual'},
          {'color': '#4AFFED ', 'min': 42, 'max': 20, 'initialValue': 42, 'val': 42, 'texte': 'Dimension (cm)', 'indication': 'This is the dimension you will cut', 'typeF': 'calcul'}
        ],

        'steps': [
          { 'idx': 1, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 2, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [1, 2, 4] },
          { 'idx': 3, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [1, 2, 4] },
          { 'idx': 4, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [1, 2, 4] },
          { 'idx': 5, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [1, 2, 4] },
          { 'idx': 6, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 7, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 8, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 9, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 10, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 11, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 12, 'modalTech': [1, 2], 'modalTip': [1], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 13, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 14, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 15, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 16, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] },
          { 'idx': 17, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https: //www.youtube.com/embed/atRb9idrKu8', 'relatedMeasures': [] }
        ],

        'tools': [
          4, 5, 8, 9, 10, 12
        ]
      },

      {
        'productName': 'Dynamic Branch',
        'productNameFrench': 'Déviation',
        'productNameDeutsch': 'Etage',
        'family': [0, 1],

        'fields': [
          { 'color': '#FF8800', 'min': 30, 'max': 20, 'initialValue': 30, 'val': 30, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#00FF33', 'min': 50, 'max': 20, 'initialValue': 50, 'val': 50, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#FF00CC', 'min': 29, 'max': 20, 'initialValue': 60, 'val': 60, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#4AFFED ', 'min': 0, 'max': 0, 'initialValue': 0, 'val': 0, 'texte': '', 'indication': '', 'typeF': 'calcul', 'nodisplay': true }
        ],

        'steps': [
          { 'idx': 1, 'modalTech':  [1, 2, 3], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 2, 'modalTech':  [1, 2, 3], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [4] },
          { 'idx': 3, 'modalTech':  [1, 2], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 4, 'modalTech':  [1], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [4] },
          { 'idx': 5, 'modalTech':  [1], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 6, 'modalTech':  [1, 2], 'modalTip':  [1, 2], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 7, 'modalTech':  [], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 8, 'modalTech':  [1], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 9, 'modalTech':  [1], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] },
          { 'idx': 10, 'modalTech':  [], 'modalTip':  [], 'urlvideo': 'https: //www.youtube.com/embed/oUdafJBnmp0', 'relatedMeasures': [] }
        ],

        'tools': [
          4, 6, 8, 9, 10, 12
        ]
      },

      {
        'productName': 'Reduction',
        'productNameFrench': 'Réduction',
        'productNameDeutsch': 'Reduzierung',
        'family': [],

        'fields': [
          { 'color': '#FF8800', 'min': 25, 'max': 20, 'initialValue': 25, 'val': 25, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#00FF33', 'min': 35, 'max': 20, 'initialValue': 35, 'val': 35, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#FF00CC', 'min': 30, 'max': 20, 'initialValue': 30, 'val': 30, 'texte': 'Dimension (cm)', 'indication': 'Should be 20cm minimum', 'typeF': 'manual' },
          { 'color': '#4AFFED', 'min': 40, 'max': 20, 'initialValue': 40, 'val': 40, 'texte': 'Dimension (cm)', 'indication': 'Should be 30cm minimum', 'typeF': 'manual' },
          { 'color': '#B300FF', 'min': 40, 'max': 20, 'initialValue': 50, 'val': 50, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'manual' },
          { 'color': '#004DFF', 'min': 109, 'max': 20, 'initialValue': 109, 'val': 109, 'texte': 'Dimension (cm)', 'indication': 'Should be 109cm maximum', 'typeF': 'manual' },
          { 'color': '#FF3300', 'min': 120, 'max': 20, 'initialValue': 120, 'val': 120, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'calcul' },
          { 'color': '#F3FF00', 'min': 120, 'max': 20, 'initialValue': 120, 'val': 120, 'texte': 'Dimension (cm)', 'indication': '', 'typeF': 'calcul', 'nodisplay': true }
        ],

        'steps': [
          { 'idx': 1, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 2, 'modalTech': [1, 2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 3, 'modalTech': [1, 2, 3, 4, 5], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [1] },
          { 'idx': 4, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [2] },
          { 'idx': 5, 'modalTech': [1, 2, 3, 4, 5, 6], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [1] },
          { 'idx': 6, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [3, 4] },
          { 'idx': 7, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [8] },
          { 'idx': 8, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 9, 'modalTech': [1,2], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 10, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 11, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 12, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [2] },
          { 'idx': 13, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [3, 7] },
          { 'idx': 14, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 15, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 16, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 17, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 18, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 19, 'modalTech': [1, 2, 3], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 20, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 21, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 22, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 23, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 24, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 25, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 26, 'modalTech': [1], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] },
          { 'idx': 27, 'modalTech': [], 'modalTip': [], 'urlvideo': 'https://www.youtube.com/embed/rAMpMU8UefQ', 'relatedMeasures': [] }
        ],

        'tools': [
          1, 2, 3, 6, 7, 9, 10, 11, 12
        ]
      }
    ]
  };

  constructor() {
    this.getDimensions();
  }

  getPieces(): Observable<Piece[]> {
    return of(this.piecesData.pieces);
  }

  getTools(): Observable<any[]> {
    return of(this.toolData.tools);
  }

  getTool(id: number): Observable<Tool> {
    return of(this.toolData.tools.filter((tool) =>  tool.idTool === id )[0]);
  }

  setDimensions(fieldsObj: any) {
    localStorage.setItem('measures', JSON.stringify(fieldsObj));
  }

  public clearDimensions(fieldsObj: any) {
    localStorage.setItem('measures', JSON.stringify(fieldsObj));
  }

  getDimensions(): Observable<any[]> {
    const retrievedObject = localStorage.getItem('measures');
    return of(JSON.parse(retrievedObject));
  }
}
