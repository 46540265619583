import { Component, OnInit } from '@angular/core';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  constructor(public coreservice: CoreserviceService) { }

  ngOnInit() {
    this.coreservice.setTitle.emit('Contacts');
    this.coreservice.showBackButton.emit({ isVisible: true, url: '/home' });
  }

}
