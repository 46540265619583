import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { IsoverProvider } from '../../app/data.service';
import { CoreserviceService } from '../coreservice.service';
import { nicoListTransitionY } from '../../animations/pages/nicoanims';

@Component({
  selector: 'app-listtools',
  templateUrl: './listtools.component.html',
  styleUrls: ['./listtools.component.scss'],
  animations: [ nicoListTransitionY ],
  host: { '[@nicoListTransitionY]': '' }
})

export class ListtoolsComponent implements OnInit {
  constructor(
    private coreService: CoreserviceService,
    private isoverProvider: IsoverProvider,
    private route: ActivatedRoute,
    public _location: Location,
  ) { }

  private sub: any;

  idPiece: number;
  idStep: number;
  tools: any[] = [];

  ngOnInit() {
    this.coreService.setTitle.emit('List Tools');
    this.coreService.showBackButton.emit({ isVisible: true, url: '/home' });

    this.sub = this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.idStep = +params['idStep'];
      if (this.idPiece >= 0) {
        this.coreService.showBackButton.emit({ isVisible: true, url: `/detail/${this.idPiece}/${this.idStep}` });
      }
    });

    this.isoverProvider.getTools().subscribe( tools => {
      if (this.idPiece >= 0) {
        this.isoverProvider.getPieces().subscribe( pieces => {
          this.tools = tools.filter((tool, index, array) => {
            return pieces[this.idPiece].tools.includes(tool.idTool);
          });
        });

      } else {
        this.tools = tools;
      }
    });
  }
}
