import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface WrongMeasureDialogData {
  title: string;
}

@Component({
  selector: 'app-wrong-measure-dialog',
  templateUrl: 'wrong_measure_dialog.html',
  styleUrls: ['wrong_measure_dialog.scss'],
})

export class WrongMeasureDialogComponent {

  title: string;

  constructor(
    public dialogRef: MatDialogRef<WrongMeasureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WrongMeasureDialogData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

}
