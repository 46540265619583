import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface
} from 'ngx-swiper-wrapper';

import { IsoverProvider, Tool } from '../data.service';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss'],
})

export class ToolComponent implements OnInit {
  config: any;
  index: number;
  swipeIndex: number;
  tool: Tool;
  transform: number;

  @ViewChild('SwiperDirective') directiveRef: SwiperDirective;

  constructor(
    private coreService: CoreserviceService,
    private isoverProvider: IsoverProvider,
    private titleService: Title,
    private route: ActivatedRoute,
  ) {
  }

  public onIndexChange(index: number): void {
    this.swipeIndex = index + 1;
  }

  ngOnInit () {
    this.index = 0;
    this.swipeIndex = 1;
    this.transform = 100;
    this.config = {
      pagination: false,
      direction: 'horizontal',
      paginationClickable: true,
      navigation: {
        nextEl: '.next',
        prevEl: '.prev',
      },
      spaceBetween: 30
    };

    this.route.params.subscribe(params => {
      const toolId = +params['id'];

      this.coreService.showBackButton.emit({
        isVisible: true
      });

      this.isoverProvider.getTool(toolId).subscribe((tool) => {
        this.tool = tool;
        this.titleService.setTitle(this.tool.nameTool);
      });
    });
  }
}
