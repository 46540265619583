import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CoreserviceService {
  public toggleSidebar: EventEmitter<any> = new EventEmitter();
  public toggleProgress: EventEmitter<any> = new EventEmitter();
  public setTitle: EventEmitter<any> = new EventEmitter();
  public setFooterVisible: EventEmitter<any> = new EventEmitter();
  public showBackButton: EventEmitter<any> = new EventEmitter();
  public setBackURL: EventEmitter<any> = new EventEmitter();
  public setIdPiece: EventEmitter<any> = new EventEmitter();
  public setIdProduct: EventEmitter<any> = new EventEmitter();
  public setIdStep: EventEmitter<any> = new EventEmitter();
  public checkIndex: EventEmitter<any> = new EventEmitter();
  public checkIndexBack: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
