import { Component, OnInit, ViewChild } from '@angular/core';
import { IsoverProvider } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-videotuto',
  templateUrl: './videotuto.component.html',
  styleUrls: ['./videotuto.component.scss']
})

export class VideotutoComponent implements OnInit {
  idBstep = 0;
  idPiece = 0;
  idStep = 0;
  product: any;
  products: any;
  selectedIndex: number;
  urlS: any;

  constructor(
    private _router: Router,
    private coreService: CoreserviceService,
    private isoprovider: IsoverProvider,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.idBstep = +params['idBstep']; // (+) converts string 'id' to a number

      this.coreService.showBackButton.emit({
        isVisible: true,
        url: `/detail/${this.idPiece}/${this.idStep}`
      });

      this.isoprovider.getPieces().subscribe( res => {
        this.products = res;
        this.product = res[this.idPiece];
        this.urlS = this.sanitizer.bypassSecurityTrustResourceUrl(this.product.steps[this.idBstep].urlvideo);
       });
   });
  }
}
