import { Routes } from '@angular/router';

import { BigstepdetailComponent } from './bigstepdetail/bigstepdetail.component';
import { ContactComponent } from './contact/contact.component';
import { DownloadComponent } from './download/download.component';
import { FillComponent } from './fill/fill.component';
import { HomeComponent } from './home/home.component';
import { LexiconComponent } from './lexicon/lexicon.component';
import { ListtoolsComponent } from './listtools/listtools.component';
import { ModaletechComponent } from './modaletech/modaletech.component';
import { ModaletipComponent } from './modaletip/modaletip.component';
import { PieceComponent } from './piece/piece.component';
import { ToolComponent } from './tools/tool.component';
import { VideotutoComponent } from './videotuto/videotuto.component';

export const routes: Routes = [
  { path: 'contact', component: ContactComponent, data: { animation: 'contactPage' } },
  { path: 'detail/:idPiece', component: BigstepdetailComponent, data: { animation: 'DetailPage' } },
  { path: 'detail/:idPiece/:currentIndex', component: BigstepdetailComponent, data: { animation: 'DetailPage' } },
  { path: 'download', component: DownloadComponent, data: { animation: 'downloadPage' } },
  { path: 'fillpage/:idPiece', component: FillComponent, data: { animation: 'FillPage' } },
  { path: 'home', component: HomeComponent, data: { animation: 'HomePage' } },
  { path: 'lexicon', component: LexiconComponent, data: { animation: 'lexiconPage' } },
  { path: 'listtools', component: ListtoolsComponent, data: { animation: 'ListPage' } },
  { path: 'listtools/:idPiece/:idStep', component: ListtoolsComponent, data: { animation: 'ListPage' } },
  { path: 'modaletech/:idPiece/:idStep', component: ModaletechComponent, data: { animation: 'ModaleTechPage' } },
  { path: 'modaletip/:idPiece/:idStep', component: ModaletipComponent, data: { animation: 'ModaleTipPage' } },
  { path: 'piece/:idPiece', component: PieceComponent, data: { animation: 'PiecePage' } },
  { path: 'tools/:id', component: ToolComponent },
  { path: 'video/:idPiece/:idBstep', component: VideotutoComponent, data: { animation: 'videoPage' } },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
