import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsoverProvider } from '../data.service';
import { Title } from '@angular/platform-browser';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-piece',
  templateUrl: './piece.component.html',
  styleUrls: ['./piece.component.scss']
})

export class PieceComponent implements OnInit {
  private sub: any;

  ArrayFamily: any[] = [];
  idPiece: number;
  longueurStep = 0;
  product: any;
  products: any;
  totalSubSteps = 0;

  constructor(
    private coreService: CoreserviceService,
    private route: ActivatedRoute,
    private isoprovider: IsoverProvider,
    private titleService: Title
  ) {
    this.ArrayFamily = [];
  }

  openSideNav(e: any) {
    this.coreService.toggleSidebar.emit();
  }

  ngOnInit() {
    localStorage.clear();

    this.coreService.showBackButton.emit({ isVisible: true, url: '/home' });

    const val1 = 10;
    this.coreService.toggleProgress.emit({ val1 });

    this.route.params.subscribe(params => {
      this.idPiece = +params['idPiece']; // (+) converts string 'id' to a number
      this.isoprovider.getPieces().subscribe( res  => {
        this.products = res;
        this.product = res[this.idPiece ];
        this.coreService.setIdProduct.emit( this.idPiece);
        this.coreService.setTitle.emit( this.product.productName);
        this.titleService.setTitle(this.product.productName );

        if (this.product.family.length > 0) {
          this.ArrayFamily = [];
          this.product.family.forEach(element => {
            this.ArrayFamily.push(this.products[element]);
          });
        } else {
          this.ArrayFamily = [];
        }

      });
    });
  }
}
