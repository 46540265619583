import { Component, OnInit, OnDestroy } from '@angular/core';
import { IsoverProvider } from '../data.service';
import { CoreserviceService } from '../coreservice.service';
import { nicoListTransitionY } from '../../animations/pages/nicoanims';

@Component({
  selector: 'app-lexicon',
  templateUrl: './lexicon.component.html',
  styleUrls: ['./lexicon.component.scss'],
  animations: [ nicoListTransitionY ],
  host: {
    '[@nicoListTransitionY]': ''
  }
})

export class LexiconComponent implements OnInit, OnDestroy {
  products: any;

  constructor(public isoprovider: IsoverProvider, private coreservice: CoreserviceService) { }

  ngOnInit() {
    this.coreservice.setTitle.emit('Lexicon');
    this.coreservice.showBackButton.emit({ isVisible: true, url: '/home' });
    this.isoprovider.getPieces().subscribe( res => {  this.products = res; });
  }

  ngOnDestroy() {
    this.products = '';
  }
}
