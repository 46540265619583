import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { IsoverProvider } from '../data.service';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  isVisible = false;
  idProduct: string;
  isPiecePage = false;
  isBigStepPage = false;
  isFillPage = false;
  isDetailPage = false;
  idStep: number;
  product: any;

  constructor(
    private isoprovider: IsoverProvider,
    private coreService: CoreserviceService,
    private router: Router,
    location: Location
  ) {
    router.events.subscribe((val) => {
      if (location.path() !== '') {

        if (location.path() === '/') {
          this.isVisible = false;
        } else {
          this.isVisible = true;
        }

        if (location.path() === '/home') {
          this.isVisible = false;
        }

        if (location.path() === '/lexicon') {
          this.isVisible = false;
        }

        if (location.path() === '/contact') {
          this.isVisible = false;
        }

        if (location.path() === '/listtools') {
          this.isVisible = false;
        }

        if (location.path() === '/modaletech') {
          this.isVisible = false;
        }

        if (location.path() === '/modaletip') {
          this.isVisible = false;
        }

        // content
        if (location.path().indexOf('/piece/') !== -1) {
          this.isPiecePage = true;
        } else {
          this.isPiecePage = false;
        }

        if (location.path().indexOf('/bigstep/') !== -1) {
          this.isBigStepPage = true;
        } else {
          this.isBigStepPage = false;
        }

        if (location.path().indexOf('/fillpage/') !== -1) {
          this.isFillPage = true;
        } else {
          this.isFillPage = false;
        }

        if (location.path().indexOf('/detail/') !== -1) {
          this.isDetailPage = true;
        } else {
          this.isDetailPage = false;
        }

      } else {
        this.isVisible = false;
        this.isPiecePage = false;
      }
    });
  }

  checkIndex() {
    this.coreService.checkIndex.emit('check');
  }

  checkIndexBack() {
    this.coreService.checkIndexBack.emit('checkBack');
  }

  ngOnInit() {
    this.coreService.setFooterVisible.subscribe((value) => {
      this.isVisible = value;
    });

    this.coreService.setIdStep.subscribe((value) => {
      this.idStep = value;
    });

    this.coreService.setIdProduct.subscribe((value) => {
      this.idProduct = value;
      this.isoprovider.getPieces().subscribe( pieces => {
        this.product = pieces[this.idProduct];
      });
    });
  }
}
