import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CoreserviceService } from '../coreservice.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})

export class DownloadComponent implements OnInit {
  deviceInfo = null;
  isAndroid = false;
  isDesktop = true;
  isIphone = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private coreService: CoreserviceService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.coreService.setTitle.emit('Download');
    this.coreService.showBackButton.emit({ isVisible: true, url: '/home' });
  }
}
